import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class Hero extends React.Component {
  render() {
    return (
      <>
        <div id="home_page" className="position-relative">
          {/* Hero for FREE version */}
          <section  className="section section-lg section-hero section-shaped">
            {/* Background circles */}
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col">
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center">
                  <img
                      alt="..."
                      className="img-fluid"
                      src={require("assets/img/brand/logo.jpg")}
                    />
                    <h3 className="display-3 text-white" >Wasteable</h3>
                    <Row className="align-items-justify justify-content-center">
                      <Col className="text-left" lg="4">
                        <p className="lead text-white">
                        There is an imperative
                        for the waste industry
                        to continually examine
                        and improve its
                        efficiency because of 
                        the direct effect on
                        energy consumption
                        and the environment
                        </p>
                      </Col>

                      <Col className="text-right" lg="4">
                        <p className="lead text-white">
                        New technology
                        traditionally has slow
                        uptake. A trusted partner
                        who can advise 
                        and provide proven, 
                        reliable and innovative 
                        solutions is required.
                        Wasteable is such a
                        trusted partner
                        </p>
                      </Col>
                    </Row>
                    
                    {/* <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        *proudly coded by
                      </small>
                      <img
                        alt="..."
                        className="ml-1"
                        style={{ height: "28px" }}
                        src={require("assets/img/brand/creativetim-white-slim.png")}
                      />
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Hero;
