import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class Icons extends React.Component {
  render() {
    return (
      <>
        <section id="about_page" className="section section-lg section-nucleo-icons">
          <Container>
            <Row className="justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">About</h2>
                <p className="lead text-left">
              <p><span>We are a new Australian company dedicated to addressing overall waste management with new concepts for treatment and disposal.</span></p>
              <p><span>Our focus is on a holistic approach encompassing environmentally friendly/enhancing solutions.</span></p>
              <p><span>The first step is to introduce improved techniques for liquid waste treatment to improve discharge quality and minimise sludge production. We are developing our own solutions, drawing on the most up to date and innovative overseas work, with local research and design complementing and adapting the methodology.</span></p>
              <p><span>Will be working in conjunction with another company that has many reference sites with municipal and industrial wastes. - </span></p>
              <p><span>One of the core pieces of equipment is membrane liquid/solid separation and we have experience with all the leading membrane manufacturers.</span></p>
              <p><span>The key industries on which we will focus are:</span></p>
              <p><ul>
                <li>
                Pharmaceutical
                </li>
                <li>
                Food
                </li>
                <li>
                Abattoir
                </li>
                <li>
                pet food
                </li>
                <li>
                confectionery
                </li>
                <li>
                baking
                </li>
                <li>
                pig and other animal slurries
                </li>
                <li>
                landfill leachate, and 
                </li>
                <li>
                municipal - both conventional waste and sludge centrate
                </li>
              </ul></p>
<p><span>Contact</span></p>
<p><span id="docs-internal-guid-283a5516-7fff-1680-40c9-d1ad71695660"><span>Email: </span><a href="mailto:info@wasteable.com.au"><span>info@wasteable.com.au</span></a></span></p>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Icons;
